import { takeLatest } from "redux-saga/effects";
import { handleLogin, handleLogOut } from "./handlers/user";
import { login, logOut } from "../ducks/userSlice";

import {
  handleGetPatient,
  handleAddPatient,
  handleDeletePatient,
  handleEditPatient,
  handleGetOnePatient,
} from "./handlers/patient";
import {
  getPatient,
  addPatient,
  deletePatient,
  editPatient,
  getOnePatient,
} from "../ducks/patientSlice";

import {
  handleGetConsultation,
  handleAddConsultation,
  handleDeleteConsultation,
  handleEditConsultation,
  handleGetOneConsultation,
} from "./handlers/consultation";
import {
  getConsultation,
  addConsultation,
  deleteConsultation,
  editConsultation,
  getOneConsultation,
} from "../ducks/consultationSlice";

import {
  handleGetControle,
  handleAddControle,
  handleDeleteControle,
  handleEditControle,
  handleGetOneControle,
} from "./handlers/controle";
import {
  getControle,
  addControle,
  deleteControle,
  editControle,
  getOneControle,
} from "../ducks/controleSlice";

import {
  handleGetOrdonnance,
  handleAddOrdonnance,
  handleDeleteOrdonnance,
  handleEditOrdonnance,
  handleGetOneOrdonnance,
} from "./handlers/ordonnance";
import {
  getOrdonnance,
  addOrdonnance,
  deleteOrdonnance,
  editOrdonnance,
  getOneOrdonnance,
} from "../ducks/ordonnanceSlice";

import {
  handleGetOrdoOrpheline,
  handleAddOrdoOrpheline,
  handleDeleteOrdoOrpheline,
  handleEditOrdoOrpheline,
  handleGetOneOrdoOrpheline,
} from "./handlers/ordoOrpheline";
import {
  getOrdoOrpheline,
  addOrdoOrpheline,
  deleteOrdoOrpheline,
  editOrdoOrpheline,
  getOneOrdoOrpheline,
} from "../ducks/ordoOrphelineSlice";

export function* watcherSaga() {
  yield takeLatest(login.type, handleLogin);
  yield takeLatest(logOut.type, handleLogOut);
  yield takeLatest(getPatient.type, handleGetPatient);
  yield takeLatest(getOnePatient.type, handleGetOnePatient);
  yield takeLatest(addPatient.type, handleAddPatient);
  yield takeLatest(deletePatient.type, handleDeletePatient);
  yield takeLatest(editPatient.type, handleEditPatient);
  yield takeLatest(getConsultation.type, handleGetConsultation);
  yield takeLatest(getOneConsultation.type, handleGetOneConsultation);
  yield takeLatest(addConsultation.type, handleAddConsultation);
  yield takeLatest(deleteConsultation.type, handleDeleteConsultation);
  yield takeLatest(editConsultation.type, handleEditConsultation);
  yield takeLatest(getOrdonnance.type, handleGetOrdonnance);
  yield takeLatest(getOneOrdonnance.type, handleGetOneOrdonnance);
  yield takeLatest(addOrdonnance.type, handleAddOrdonnance);
  yield takeLatest(deleteOrdonnance.type, handleDeleteOrdonnance);
  yield takeLatest(editOrdonnance.type, handleEditOrdonnance);
  yield takeLatest(getControle.type, handleGetControle);
  yield takeLatest(getOneControle.type, handleGetOneControle);
  yield takeLatest(addControle.type, handleAddControle);
  yield takeLatest(deleteControle.type, handleDeleteControle);
  yield takeLatest(editControle.type, handleEditControle);
  yield takeLatest(getOrdoOrpheline.type, handleGetOrdoOrpheline);
  yield takeLatest(getOneOrdoOrpheline.type, handleGetOneOrdoOrpheline);
  yield takeLatest(addOrdoOrpheline.type, handleAddOrdoOrpheline);
  yield takeLatest(deleteOrdoOrpheline.type, handleDeleteOrdoOrpheline);
  yield takeLatest(editOrdoOrpheline.type, handleEditOrdoOrpheline);
}
