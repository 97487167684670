/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatient,
  deletePatient,
  setPatient,
  editPatient,
} from "../../ducks/patientSlice";
import { clearMessage } from "../../ducks/messageSlice";
import MaterialReactTable from "material-react-table";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { AppBar, Dialog, Toolbar, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisiteAdd from "../Visite/VisiteAdd.js";
import TodayIcon from "@mui/icons-material/Today";
const PatientList = () => {
  const { message } = useSelector((state) => state.message);
  const [tableData, setTableData] = useState(() => []);
  const { data, loading, success } = useSelector((state) => state.patient);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [patientId, setPatientId] = useState(0);
  const handleClickOpen = (row) => {
    console.log(row.getValue("id"));
    setPatientId(row.getValue("id"));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(clearMessage());
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getPatient());
  }, [dispatch]);

  useEffect(() => {
    setTableData(Object.values(data));
  }, [data]);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    tableData[row.index] = values;
    values["status"] = values["status"] == "false" ? false : true;
    dispatch(editPatient({ id: values.id, data: values }));

    setTableData([...tableData]);
    exitEditingMode(); //required to exit editing mode and close modal
  };

  const handleDeleteRow = useCallback(
    (row) => {
      if (
        !confirm(
          `Tu veux vraiment supprimer le patient ${row.getValue(
            "nom"
          )} ${row.getValue("prenom")}`
        )
      ) {
        return;
      }
      dispatch(deletePatient(row.getValue("id")));
      setPatient({ data: [...tableData] });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData, dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "status",
        enableColumnOrdering: false,
        size: 120,
        //custom conditional format and styling
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() == true
                  ? theme.palette.success.light
                  : theme.palette.error.light,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "ch",
              p: "0.6rem",
            })}
          ></Box>
        ),
      },
      {
        accessorKey: "id",
        header: "id",
        enableColumnOrdering: false,
        enableResizing: true,
        minSize: 10, //min size enforced during resizing
        maxSize: 120, //max size enforced during resizing
        size: 90,
        Cell: ({ cell }) => (
          <Link href={"/patient/" + cell.getValue()}>{cell.getValue()}</Link>
        ),
        sortDescFirst: false,
      },
      {
        accessorKey: "nom",
        header: "nom",
        //accessorFn function that combines multiple data together
        accessorFn: (row) => `${row.nom}`,
        enableColumnOrdering: false,
      },
      { accessorKey: "prenom", header: "prenom", enableColumnOrdering: false },
      {
        accessorKey: "sexe",
        header: "sexe",
        enableColumnOrdering: false,
        size: 105,
      },
      {
        accessorKey: "dateNaissance",
        header: "date_naissance",
        enableColumnOrdering: false,
      },
      { accessorKey: "cin", header: "cin", enableColumnOrdering: false },
      {
        accessorKey: "ajouter",
        header: "Ajouter",
        enableColumnOrdering: false,
        Cell: ({ cell }) => dayjs(cell.getValue()).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "adresse",
        header: "adresse",
        enableColumnOrdering: false,
      },
      { accessorKey: "tel", header: "tel", enableColumnOrdering: false },
      {
        accessorKey: "atcd",
        header: "atcd",
        enableColumnOrdering: false,
      },
      {
        accessorKey: "couvertureSocial",
        header: "Couverture Social",
        enableColumnOrdering: false,
      },
      {
        accessorKey: "lastVisite",
        header: "Dernière Visite",
        enableColumnOrdering: false,
      },
      {
        accessorKey: "typeVisite",
        header: "Type Visite",
        enableColumnOrdering: false,
      },
    ],
    []
  );

  return (
    <div className="container">
      {message && (
        <div className="form-group">
          {success === true ? (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          ) : (
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          )}
        </div>
      )}
      <MaterialReactTable
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 100,
          },
        }}
        //Simply adding a table title to the top-left of the top toolbar
        renderTopToolbarCustomActions={() => (
          <Typography variant="h3">{`Patients {${
            Object.keys(tableData).length
          }}`}</Typography>
        )}
        enableStickyHeader
        enableDensityToggle={false}
        columns={columns}
        data={tableData}
        localization={MRT_Localization_FR}
        state={{ isLoading: loading }}
        editingMode="modal" //default
        enableColumnOrdering
        positionGlobalFilter="right" //show the global filter on the left side of the top toolbar
        initialState={{
          showColumnFilters: true,
          showGlobalFilter: true, //show the global filter by default
          pagination: { pageSize: 25, pageIndex: 0 },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search all users",
          sx: { minWidth: "300px" },
          variant: "outlined",
        }}
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({ row, table }) => (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "center",
            }}
          >
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                className="p-0"
                onClick={() => table.setEditingRow(row)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                className="p-0"
                color="error"
                onClick={() => handleDeleteRow(row)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Ajouter Visite">
              <IconButton
                className="p-0"
                color="secondary"
                onClick={() => handleClickOpen(row)}
              >
                <TodayIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Nouvelle Visite
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={scroll === "paper"}>
          <VisiteAdd patient={patientId} />
        </DialogContent>
      </Dialog>
      {/*     <MaterialReactTable
        columns={columns}
        data={data}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10],
          showFirstLastPageButtons: false,
        }}
      /> */}
    </div>
  );
};

export default PatientList;
