import { call, put } from "redux-saga/effects";
import {
  setOrdoOrpheline,
  getOrdoOrphelineFailure,
  addOrdoOrphelineSuccess,
  addOrdoOrphelineFailure,
  deleteOrdoOrphelineSuccess,
  deleteOrdoOrphelineFailure,
  editOrdoOrphelineSuccess,
  editOrdoOrphelineFailure,
  setOneOrdoOrpheline,
} from "../../ducks/ordoOrphelineSlice";
import { setMessage } from "../../ducks/messageSlice";
import {
  requestGetOrdoOrpheline,
  requestAddOrdoOrpheline,
  requestDeleteOrdoOrpheline,
  requestEditOrdoOrpheline,
  requestGetOneOrdoOrpheline,
} from "../requests/ordoOrpheline";

export function* handleGetOrdoOrpheline() {
  try {
    const response = yield call(requestGetOrdoOrpheline);
    const { data } = response;
    yield put(setOrdoOrpheline({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getOrdoOrphelineFailure(message));
  }
}
export function* handleGetOneOrdoOrpheline(action) {
  try {
    const response = yield call(requestGetOneOrdoOrpheline, action.payload);
    const { data } = response;
    yield put(setOneOrdoOrpheline({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getOrdoOrphelineFailure(message));
  }
}
export function* handleAddOrdoOrpheline(action) {
  try {
    const response = yield call(requestAddOrdoOrpheline, action.payload);
    const { data } = response;
    yield put(setMessage("OrdoOrpheline ajouter !!"));
    yield put(addOrdoOrphelineSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(addOrdoOrphelineFailure(message));
  }
}

export function* handleEditOrdoOrpheline(action) {
  try {
    const response = yield call(
      requestEditOrdoOrpheline,
      action.payload.id,
      action.payload.data
    );
    const { data } = response;
    yield put(setMessage("OrdoOrpheline modifier !!"));
    yield put(editOrdoOrphelineSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(editOrdoOrphelineFailure(message));
    yield put(setMessage(message));
  }
}
export function* handleDeleteOrdoOrpheline(action) {
  try {
    const response = yield call(requestDeleteOrdoOrpheline, action.payload);
    const { data } = response;
    yield put(setMessage("OrdoOrpheline supprimer !!"));
    yield put(deleteOrdoOrphelineSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(deleteOrdoOrphelineFailure(message));
    yield put(setMessage(message));
  }
}
