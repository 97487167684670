import { call, put } from "redux-saga/effects";
import { loginUserFailure, loginUserSuccess } from "../../ducks/userSlice";
import { requestLogin, requestLogOut } from "../requests/user";
import { setMessage, clearMessage } from "../../ducks/messageSlice";
import history from "../../history";

export function* handleLogin(action) {
  try {
    const data = yield call(requestLogin, action.payload);
    const { token, role } = data.data.user;
    localStorage.userJWT = token;
    yield put(loginUserSuccess(role));
    history.push("./profile");
    window.location.reload();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(loginUserFailure(message));
  }
}

export function* handleLogOut(action) {
  try {
    const response = yield call(requestLogOut);
    const { data } = response;
    yield put(setMessage(data.message));
    localStorage.removeItem("userJWT");
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
  }
}
