/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";

const user = localStorage.getItem("userJWT");

const initialState = user
  ? { isLoggedIn: true }
  : { isLoggedIn: false, role: null };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getUser() {},
    setUser(state, action) {
      const userData = action.payload;
      return { ...state, ...userData, isLoggedIn: true };
    },
    login() {},
    loginUserSuccess(state, action) {
      const data = action.payload;
      return { ...state, isLoggedIn: true, role: data };
    },
    loginUserFailure(state, action) {
      const data = action.payload;
      return { ...state, isLoggedIn: false, erreur: data };
    },
    logOut(state, action) {
      return { ...state, isLoggedIn: false, role: null };
    },
  },
});
export const {
  getUser,
  setUser,
  login,
  loginUserSuccess,
  loginUserFailure,
  logOut,
} = userSlice.actions;

export default userSlice.reducer;
