import React, { useState, useEffect, Fragment } from "react";
import { lastPart } from "../../common/Fonction.js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import parse from "html-react-parser";
dayjs.extend(relativeTime);
import { clearMessage } from "../../ducks/messageSlice";
import { getOneControle, deleteControle } from "../../ducks/controleSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Tooltip,
  DialogContent,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import OrdonnanceAdd from "../Ordonnance/OrdonnanceAdd.js";

const Controle = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const { dataOneControle } = useSelector((state) => state.controle);
  const { message } = useSelector((state) => state.message);
  const [data, setData] = useState([]);
  const [nbOrdonnance, setNbOrdonnance] = useState("");
  const [dataOrdonnance, setDataOrdonnance] = useState([]);
  const dispatch = useDispatch();
  const id = lastPart(location.pathname);

  const handleClickOpen = () => () => {
    setOpen(true);
    scroll;
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(getOneControle(id));
    dispatch(clearMessage());
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getOneControle(id));
  }, [dispatch, id]);

  useEffect(() => {
    setData(dataOneControle);
    if (dataOneControle && Object.keys(dataOneControle).length > 0) {
      setNbOrdonnance(Object.keys(dataOneControle.ordonnances).length);
      setDataOrdonnance(
        [...dataOneControle.ordonnances].sort((a, b) => b.id - a.id)
      );
    }
  }, [dataOneControle, data]);

  const deleteOrdo = (id) => {
    if (!confirm(`Tu veux vraiment supprimer l'ordonnance n°: ${id}`)) {
      return;
    }
    setDataOrdonnance(data.ordonnances.filter((item) => item.id !== id));
    dispatch(deleteControle(id));
    setNbOrdonnance(nbOrdonnance - 1);
  };

  return (
    <div className="col-md-12">
      {message && (
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      )}
      {data && data.consultation && dataOrdonnance && (
        <Fragment>
          <h1 className="text-center mb-4">Controle n° {data.id}</h1>
          <div className="container overflow-hidden text-center">
            <div className="row row-cols-3 g-4 g-lg-3">
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Date de controle :</strong>
                  </p>
                  <p>{dayjs(data.ajouter).format("DD-MM-YYYY")}</p>
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Evolution :</strong>
                  </p>
                  {parse(data.evolution)}
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Conduite à tenir :</strong>
                  </p>
                  {data.cat && parse(data.cat)}
                </div>
              </div>
            </div>{" "}
            <div className="g-4 g-lg-3 mt-3 border p-3">
              <div className="row justify-content-around">
                <div className="col-4 p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Consultation n°: {data.consultation.id}</strong>
                  </p>
                  <p>
                    {dayjs(data.consultation.dateConsultation).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </div>
              </div>
              <div className="row row-cols-3 g-4 g-lg-3 mt-3">
                <div className="col">
                  <div className="p-2 border bg-light">
                    <strong>Poids</strong> : {data.consultation.poids} Kg
                    <br />
                    <strong>Tension</strong> : {data.consultation.tension}
                    <br />
                    <strong>Température</strong> :{" "}
                    {data.consultation.temperature}
                  </div>
                </div>
                <div className="col">
                  <div className="p-2 border bg-light">
                    <p className="mt-2">
                      <strong>Motif :</strong>
                    </p>
                    {parse(data.consultation.motif)}
                  </div>
                </div>
                <div className="col">
                  <div className="p-2 border bg-light">
                    <p className="mt-2">
                      <strong>Atcd :</strong>
                    </p>
                    {parse(data.consultation.atcd)}
                  </div>
                </div>
                <div className="col">
                  <div className="p-2 border bg-light">
                    <p className="mt-2">
                      <strong>Diagnostic :</strong>
                    </p>
                    {parse(data.consultation.diagnostic)}
                  </div>
                </div>
                <div className="col">
                  <div className="p-2 border bg-light">
                    <p className="mt-2">
                      <strong>Conduite à tenir :</strong>
                    </p>
                    {parse(data.consultation.cat)}
                  </div>
                </div>
                <div className="col">
                  <div className="p-2 border bg-light">
                    <p className="mt-2">
                      <strong>Traitement en cours :</strong>
                    </p>
                    {parse(data.consultation.traitementEnCours)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-3 g-4 g-lg-3 mt-3">
              <div className="col-6">
                <div className="col pt-2 p-3 pb-0 border">
                  <div className="row row-cols-5 d-flex align-items-center pt-2 pb-2">
                    <div className="col-6">
                      <div className=" bg-light">
                        <h5 className="m-0">
                          {nbOrdonnance}
                          {nbOrdonnance > 1 ? " Ordonnances" : " Ordonnance"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="bg-light p-2">
                        <LoadingButton
                          onClick={handleClickOpen()}
                          endIcon={<SendIcon />}
                          variant="contained"
                        >
                          Ajouter Ordonnance
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                  {Object.keys(dataOrdonnance).length > 0 && (
                    <div className="pt-2 bg-light">
                      {dataOrdonnance.map((item, i) => (
                        <div
                          key={i}
                          className="row row-cols-5 mt-2 mb-2 border d-flex align-items-center justify-content-center"
                        >
                          <div className="col-4">
                            <div className="bg-light">
                              <Link
                                className="text-start"
                                to={`/ordonnance/${item.id}`}
                              >
                                Ordonnance : {item.id}
                              </Link>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <strong>Type</strong> <p>{item.type}</p>{" "}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <Tooltip
                                arrow
                                placement="right"
                                title="Supprimer"
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => deleteOrdo(item.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      <Dialog fullScreen open={open} onClose={handleClose} scroll={scroll}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Nouvelle Ordonnance
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={scroll === "paper"}>
          <OrdonnanceAdd controle={data.id} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Controle;
