/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";

const ConsultationSlice = createSlice({
  name: "consultation",
  initialState: {
    loading: false,
    data: {},
    addconsult: { loading: false, data: {} },
  },
  reducers: {
    getConsultation(state) {
      return { ...state, data: {}, erreur: null, loading: true };
    },
    setConsultation(state, action) {
      const data = action.payload;
      return { ...state, data: data, erreur: null, loading: false };
    },
    getConsultationFailure(state, action) {
      const erreur = action.payload;
      return { ...state, erreur: erreur, loading: false };
    },
    getOneConsultation(state) {
      return { ...state, data: {}, erreur: null, dataOneConslt: {} };
    },
    setOneConsultation(state, action) {
      const data = action.payload;
      return { ...state, data: {}, erreur: null, dataOneConslt: data };
    },
    addConsultation(state) {
      return { ...state, addconsult: { loading: true } };
    },
    addConsultationSuccess(state, action) {
      const data = action.payload;
      return {
        ...state,
        addconsult: { loading: false, success: true, data: data },
      };
    },
    clearAddConsultation(state) {
      return { ...state, addconsult: { success: null } };
    },
    addConsultationFailure(state, action) {
      const data = action.payload;
      return {
        ...state,
        erreur: data,
        loading: false,
        addconsult: { loading: false, success: false },
      };
    },
    editConsultation(state) {
      return { ...state };
    },
    editConsultationSuccess(state) {
      return { ...state, success: true };
    },
    editConsultationFailure(state) {
      return {
        ...state,
        success: false,
      };
    },
    deleteConsultation(state) {
      return { ...state };
    },
    deleteConsultationSuccess(state) {
      return { ...state, success: true };
    },
    deleteConsultationFailure(state) {
      return { ...state, success: false };
    },
  },
});
export const {
  getConsultation,
  setConsultation,
  getConsultationFailure,
  addConsultation,
  addConsultationSuccess,
  addConsultationFailure,
  deleteConsultation,
  deleteConsultationFailure,
  deleteConsultationSuccess,
  editConsultation,
  editConsultationFailure,
  editConsultationSuccess,
  clearAddConsultation,
  getOneConsultation,
  setOneConsultation,
} = ConsultationSlice.actions;

export default ConsultationSlice.reducer;
