import { call, put } from "redux-saga/effects";
import {
  setConsultation,
  getConsultationFailure,
  addConsultationSuccess,
  addConsultationFailure,
  deleteConsultationSuccess,
  deleteConsultationFailure,
  editConsultationSuccess,
  editConsultationFailure,
  setOneConsultation,
} from "../../ducks/consultationSlice";
import { setMessage } from "../../ducks/messageSlice";
import {
  requestGetConsultation,
  requestAddConsultation,
  requestDeleteConsultation,
  requestEditConsultation,
  requestGetOneConsultation,
} from "../requests/consultation";

export function* handleGetConsultation() {
  try {
    const response = yield call(requestGetConsultation);
    const { data } = response;
    yield put(setConsultation({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getConsultationFailure(message));
  }
}

export function* handleGetOneConsultation(action) {
  try {
    const response = yield call(requestGetOneConsultation, action.payload);
    const { data } = response;
    yield put(setOneConsultation({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getConsultationFailure(message));
  }
}

export function* handleAddConsultation(action) {
  try {
    const response = yield call(requestAddConsultation, action.payload);
    const { data } = response;
    yield put(setMessage("Consultation ajouter !!"));
    yield put(addConsultationSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(addConsultationFailure(message));
  }
}

export function* handleEditConsultation(action) {
  try {
    const response = yield call(
      requestEditConsultation,
      action.payload.id,
      action.payload.data
    );
    const { data } = response;
    yield put(setMessage("Consultation modifier !!"));
    yield put(editConsultationSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(editConsultationFailure(message));
    yield put(setMessage(message));
  }
}
export function* handleDeleteConsultation(action) {
  try {
    const response = yield call(requestDeleteConsultation, action.payload);
    const { data } = response;
    yield put(setMessage("Consultation supprimer !!"));
    yield put(deleteConsultationSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(deleteConsultationFailure(message));
    yield put(setMessage(message));
  }
}
