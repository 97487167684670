import axios from "axios";
import { url, reqInstance } from "../../common/authHeader";

export function requestLogin(data) {
  return axios({
    method: "post",
    url: `${url}login`,
    data: data,
  });
}

export function requestLogOut() {
  return reqInstance.get(`${url}logout`);
}
