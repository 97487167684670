import { url, reqInstance } from "../../common/authHeader";

export function requestGetPatient() {
  return reqInstance.get(`${url}patients?order[id]=desc`);
}

export function requestGetOnePatient(id) {
  return reqInstance.get(`${url}patients/${id}`);
}

export function requestAddPatient(data) {
  return reqInstance.post(`${url}add-patient`, data);
}

export function requestEditPatient(id, data) {
  return reqInstance.put(`${url}patients/${id}`, data);
}

export function requestDeletePatient(id) {
  return reqInstance.delete(`${url}patients/${id}`);
}
