/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";

const ControleSlice = createSlice({
  name: "controle",
  initialState: {
    loading: false,
    data: {},
    dataOneControle: {},
    addcontrole: { loading: false, data: {} },
  },
  reducers: {
    resetControle(state) {
      return {
        ...state,
        loading: false,
        data: {},
        dataOne: {},
        addcontrole: { loading: false, data: {} },
      };
    },
    getControle(state) {
      return { ...state, data: {}, erreur: null, loading: true };
    },
    setControle(state, action) {
      const data = action.payload;
      return { ...state, data: data, erreur: null, loading: false };
    },
    getControleFailure(state, action) {
      const erreur = action.payload;
      return { ...state, erreur: erreur, loading: false };
    },
    getOneControle(state) {
      return { ...state, data: {}, erreur: null, dataOneControle: {} };
    },
    setOneControle(state, action) {
      const data = action.payload;
      return { ...state, data: {}, erreur: null, dataOneControle: data };
    },
    addControle(state) {
      return { ...state, addcontrole: { loading: true, data: {} } };
    },
    addControleSuccess(state, action) {
      const data = action.payload;
      return {
        ...state,
        addcontrole: { loading: false, success: true, data: data },
      };
    },
    addControleClear(state) {
      return {
        ...state,
        addcontrole: { loading: false, data: {}, success: null },
      };
    },
    addControleFailure(state, action) {
      const data = action.payload;
      return {
        ...state,
        erreur: data,
        loading: false,
        addcontrole: { loading: false, success: false },
      };
    },
    editControle(state) {
      return { ...state };
    },
    editControleSuccess(state) {
      return { ...state, success: true };
    },
    editControleFailure(state) {
      return {
        ...state,
        success: false,
      };
    },
    deleteControle(state) {
      return { ...state };
    },
    deleteControleSuccess(state) {
      return { ...state, success: true };
    },
    deleteControleFailure(state) {
      return { ...state, success: false };
    },
  },
});
export const {
  getControle,
  setControle,
  getControleFailure,
  addControle,
  addControleSuccess,
  addControleFailure,
  deleteControle,
  deleteControleFailure,
  deleteControleSuccess,
  editControle,
  editControleFailure,
  editControleSuccess,
  addControleClear,
  getOneControle,
  setOneControle,
  resetControle,
} = ControleSlice.actions;

export default ControleSlice.reducer;
