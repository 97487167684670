/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConsultation, deleteConsultation, setConsultation, editConsultation } from "../../ducks/consultationSlice";
import { clearMessage } from "../../ducks/messageSlice";
import MaterialReactTable from "material-react-table";
import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import dayjs from "dayjs";

const ConsultationList = () => {

  const { message } = useSelector((state) => state.message);
  const [tableData, setTableData] = useState(() => []);
  const { data, loading, success } = useSelector((state) => state.consultation);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getConsultation());
  }, [dispatch]);

  useEffect(() => {
    const dataF = Object.values(data)
    setTableData(dataF)
  },[data])

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    tableData[row.index] = values;
    const id = Object.values(data)['0'].patient.id
    const dateConsult = Object.values(data)['0'].dateConsultation
    delete values.patient
    delete values.dateConsultation

    values['prix'] = parseInt(values['prix'])
    values['payed'] = parseInt(values['payed'])
    values['reste'] = parseInt(values['reste'])
    dispatch(editConsultation({ id : values.id, data : values }))
    
    tableData['0']['patient'] = {id:id}
    tableData['0']['dateConsultation'] = dateConsult
    setTableData([...tableData]);
    exitEditingMode(); //required to exit editing mode and close modal
  };

  const handleDeleteRow = useCallback(
    (row) => {
      if (!confirm(`Tu veux vraiment supprimer la Consultation ${row.getValue("id")}`)) {
        return;
      }
      dispatch(deleteConsultation(row.getValue("id")))
      setConsultation({data:[...tableData]})

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData, dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "id",
        enableColumnOrdering: false,
        enableResizing: true,
        minSize: 10, //min size enforced during resizing
        maxSize: 120, //max size enforced during resizing
        size: 90,
        Cell: ({ cell }) => (
          <Link href={"/consultation/" + cell.getValue()}>{cell.getValue()}</Link>
        ),
        sortDescFirst: false,
      },
      { 
        accessorKey: "patient", header: "patient Id", enableColumnOrdering: false,
        Cell: ({ cell }) => (
          <Link target="_blank" href={"/patient/" + cell.getValue().id}>{cell.getValue().id}</Link>
        ),
      },
      {
        accessorKey: "dateConsultation",
        header: "Date consultation",
        enableColumnOrdering: false,
        Cell: ({ cell}) => dayjs(cell.getValue()).format("DD/MM/YYYY"),
      },
      { accessorKey: "temperature", header: "temperature", enableColumnOrdering: false },
      { accessorKey: "tension", header: "tension", enableColumnOrdering: false },
      {
        accessorKey: "poids",
        header: "poids",
        enableColumnOrdering: false,
      },
      {
        accessorKey: "motif",
        header: "motif",
        enableColumnOrdering: false,
      },
      { accessorKey: "traitementEnCours", header: "traitementEnCours", enableColumnOrdering: false },
      {
        accessorKey: "atcd",
        header: "atcd",
        enableColumnOrdering: false,
      },
      { accessorKey: "diagnostic", header: "diagnostic", enableColumnOrdering: false },
      {
        accessorKey: "cat",
        header: "cat",
        enableColumnOrdering: false,
      },
      {
        accessorKey: "prix",
        header: "prix",
        enableColumnOrdering: false,
      },
      {
        accessorKey: "payed",
        header: "payed",
        enableColumnOrdering: false,
      },

      {
        accessorKey: "reste",
        header: "reste",
        enableColumnOrdering: false,
      },
    ],
    []
  );

  return (
    <div className="container">
      {message && (
        <div className="form-group">
          {success === true ? (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          ):(  
          <div className="alert alert-danger" role="alert">
            {message}
          </div>)}
        </div>
      )}
      <MaterialReactTable
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 100,
          },
        }}
        //Simply adding a table title to the top-left of the top toolbar
        renderTopToolbarCustomActions={() => (
          <Typography variant="h3">{`Consultations {${Object.keys(tableData).length}}`}</Typography>
        )}
        enableStickyHeader
        enableDensityToggle={false}
        columns={columns}
        data={tableData}
        localization={MRT_Localization_FR}
        state={{ isLoading: loading }}
        editingMode="modal" //default
        enableColumnOrdering
        positionGlobalFilter="right" //show the global filter on the left side of the top toolbar
        initialState={{
          showColumnFilters: true,
          showGlobalFilter: true, //show the global filter by default
          pagination: { pageSize: 25, pageIndex: 0 },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search all users",
          sx: { minWidth: "300px" },
          variant: "outlined",
        }}
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "0.1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
      {/*     <MaterialReactTable
        columns={columns}
        data={data}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10],
          showFirstLastPageButtons: false,
        }}
      /> */}
    </div>
  );
};

export default ConsultationList;
