import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { clearMessage, setMessage } from "../../ducks/messageSlice";
import PropTypes from "prop-types";
import { url, reqInstance } from "../../common/authHeader";

import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
const VisiteAdd = (props) => {
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const dispatch = useDispatch();
  console.log(props);
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    patient: props.patient,
    type: "",
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Ce champ est obligatoire"),
  });

  const handleVisiteAdd = (formValue) => {
    const data = {
      ...formValue,
    };

    reqInstance
      .post(`${url}add-visite`, data)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        dispatch(setMessage(res.data));
      })
      .catch((error) => {
        setLoading(false);
        setSuccess(false);
      });
  };

  return (
    <div className="col-md-12 signup-form">
      {message && (
        <div className="form-group">
          {success === true ? (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          ) : (
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          )}
        </div>
      )}

      <div className="card-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleVisiteAdd}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row mt-2 mb-2 d-flex align-items-center justify-content-center">
                <div className="col-md-4">
                  <div className="my-radio-group mb-2">Type de visite</div>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className={
                          errors.type && touched.type
                            ? "form-check-input is-invalid"
                            : "form-check-input "
                        }
                        type="radio"
                        name="type"
                        value="Consultation"
                      />
                      <label className="form-check-label">Consultation</label>
                    </div>

                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className={
                          errors.type && touched.type
                            ? "form-check-input is-invalid"
                            : "form-check-input "
                        }
                        type="radio"
                        name="type"
                        value="Controle"
                      />
                      <label className="form-check-label">Controle</label>
                    </div>

                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className={
                          errors.type && touched.type
                            ? "form-check-input is-invalid"
                            : "form-check-input "
                        }
                        type="radio"
                        name="type"
                        value="Bilan"
                      />
                      <label className="form-check-label">Bilan</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center ">
                  <LoadingButton
                    type="submit"
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    color={
                      success === true
                        ? "success"
                        : success === false
                        ? "error"
                        : "primary"
                    }
                    disabled={success}
                  >
                    Ajouter
                  </LoadingButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

VisiteAdd.propTypes = {
  patient: PropTypes.number,
};
export default VisiteAdd;
