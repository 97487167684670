import { call, put } from "redux-saga/effects";
import {
  setControle,
  getControleFailure,
  addControleSuccess,
  addControleFailure,
  deleteControleSuccess,
  deleteControleFailure,
  editControleSuccess,
  editControleFailure,
  setOneControle,
} from "../../ducks/controleSlice";
import { setMessage } from "../../ducks/messageSlice";
import {
  requestGetControle,
  requestAddControle,
  requestDeleteControle,
  requestEditControle,
  requestGetOneControle,
} from "../requests/controle";

export function* handleGetControle() {
  try {
    const response = yield call(requestGetControle);
    const { data } = response;
    yield put(setControle({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getControleFailure(message));
  }
}

export function* handleAddControle(action) {
  try {
    const response = yield call(requestAddControle, action.payload);
    const { data } = response;
    yield put(setMessage("Controle ajouter !!"));
    yield put(addControleSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(addControleFailure(message));
  }
}

export function* handleGetOneControle(action) {
  try {
    const response = yield call(requestGetOneControle, action.payload);
    const { data } = response;
    yield put(setOneControle({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getControleFailure(message));
  }
}

export function* handleEditControle(action) {
  try {
    const response = yield call(
      requestEditControle,
      action.payload.id,
      action.payload.data
    );
    const { data } = response;
    yield put(setMessage("Controle modifier !!"));
    yield put(editControleSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(editControleFailure(message));
    yield put(setMessage(message));
  }
}
export function* handleDeleteControle(action) {
  try {
    const response = yield call(requestDeleteControle, action.payload);
    const { data } = response;
    yield put(setMessage("Controle supprimer !!"));
    yield put(deleteControleSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(deleteControleFailure(message));
    yield put(setMessage(message));
  }
}
