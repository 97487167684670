import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lastPart } from "../../common/Fonction.js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
dayjs.extend(relativeTime);
import { getOnePatient } from "../../ducks/patientSlice";
import {
  clearAddConsultation,
  deleteConsultation,
} from "../../ducks/consultationSlice";
import { deleteOrdoOrpheline } from "../../ducks/ordoOrphelineSlice";
import { clearMessage } from "../../ducks/messageSlice";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ConsultationAdd from "../Consultation/ConsultationAdd";
import { DialogContent, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import OrdoOrphelineAdd from "../OrdoOrpheline/OrdoOrphelineAdd";

const Patient = () => {
  const [open, setOpen] = useState(false);
  const [openOrdoOrph, setOpenOrdoOrph] = useState(false);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { dataOne, success } = useSelector((state) => state.patient);
  const [data, setData] = useState({});
  const [age, setAge] = useState("");
  const [nbConsult, setNbConsult] = useState(0);
  const [dataConsult, setDataConsult] = useState([]);
  const [dataOrdoOrpheline, setDataOrdoOrpheline] = useState([]);
  const [nbOrdoOrpheline, setNbOrdoOrpheline] = useState(0);

  const id = lastPart(location.pathname);

  const [scroll] = useState("paper");
  const handleClickOpenConsult = () => () => {
    setOpen(true);
    scroll;
    dispatch(clearAddConsultation());
  };
  const handleCloseConsult = () => {
    setOpen(false);
    dispatch(clearMessage());
    dispatch(getOnePatient(id));
  };

  const handleClickOpenOrdoOrph = () => () => {
    setOpenOrdoOrph(true);
    scroll;
  };
  const handleCloseOrdoOrph = () => {
    setOpenOrdoOrph(false);
    dispatch(clearMessage());
    dispatch(getOnePatient(id));
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getOnePatient(id));
  }, [dispatch, id]);

  useEffect(() => {
    setData(dataOne);
    if (dataOne && Object.keys(dataOne).length > 0) {
      setAge(dayjs(dataOne.dateNaissance).toNow(true));
      setNbConsult(Object.keys(dataOne.consultations).length);
      setDataConsult([...dataOne.consultations].sort((a, b) => b.id - a.id));
      setNbOrdoOrpheline(
        dataOne.ordoOrphelines && Object.keys(dataOne.ordoOrphelines).length
      );
      setDataOrdoOrpheline(
        dataOne.ordoOrphelines &&
          [...dataOne.ordoOrphelines].sort((a, b) => b.id - a.id)
      );
    }
  }, [dataOne, data]);

  const deleteConsult = (id) => {
    if (!confirm(`Tu veux vraiment supprimer la consultation n°: ${id}`)) {
      return;
    }
    setDataConsult(dataConsult.filter((item) => item.id !== id));
    dispatch(deleteConsultation(id));
    setNbConsult(nbConsult - 1);
  };

  const deleteordoOrpheline = (id) => {
    if (
      !confirm(`Tu veux vraiment supprimer l'ordonnance orpheline n°: ${id}`)
    ) {
      return;
    }
    setDataOrdoOrpheline(dataOrdoOrpheline.filter((item) => item.id !== id));
    dispatch(deleteOrdoOrpheline(id));
    setNbOrdoOrpheline(nbOrdoOrpheline - 1);
  };

  return (
    data &&
    dataOne &&
    dataConsult && (
      <div className="col-md-12">
        {message && success === false && (
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        )}
        <div className="container overflow-hidden text-center">
          <div className="row gy-3">
            <div className="col-6">
              <div className="p-2 border bg-light">
                <p className="mt-2">
                  <strong>Nom - Prenom :</strong>
                </p>
                <p>
                  {data.nom} {data.prenom}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="p-2 border bg-light">
                <p className="mt-2">
                  <strong>Sexe : </strong>
                  {data.sexe}
                </p>
                <p>
                  <strong>Age : </strong>
                  {age}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="p-2 border bg-light">
                <p className="mt-2">
                  <strong>couverture Social :</strong>
                </p>
                <p>{data.couvertureSocial}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="p-2 border bg-light">
                <p className="mt-2">
                  <strong>Dernière visite : </strong>
                  {data.lastVisite}
                </p>
                <p>
                  <strong>Type : </strong>
                  {data.typeVisite}
                </p>
              </div>
            </div>

            <div className="row m-3 d-flex align-items-center">
              <div className="col-md-6 offset-md-3 border p-2">
                <p className="mt-2">
                  <strong>Antécédent : </strong>
                </p>
                {data.atcd && parse(data.atcd)}
              </div>
            </div>
            <div className="col-6">
              <div className="col pt-2 p-3 pb-0 border">
                <div className="row row-cols-5 d-flex align-items-center pt-2 pb-2">
                  <div className="col-6">
                    <div className=" bg-light">
                      <h5 className="m-0">
                        {nbConsult}
                        {nbConsult > 1 ? " Consultations" : " Consultation"}
                      </h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="bg-light p-2">
                      <LoadingButton
                        onClick={handleClickOpenConsult()}
                        endIcon={<SendIcon />}
                        variant="contained"
                      >
                        Ajouter Consultation
                      </LoadingButton>
                    </div>
                  </div>
                </div>
                {dataConsult && Object.keys(dataConsult).length > 0 && (
                  <div className="pt-2 bg-light">
                    {dataConsult.map((item, i) => (
                      <div
                        key={i}
                        className="row row-cols-5 mt-2 mb-2 border d-flex align-items-center justify-content-center"
                      >
                        <div className="col-4">
                          <div className="bg-light">
                            <Link
                              className="text-start"
                              to={`/consultation/${item.id}`}
                            >
                              Consultation : {item.id}
                            </Link>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="bg-light">
                            <strong>Motif</strong> {parse(item.motif)}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="bg-light">
                            <Tooltip arrow placement="right" title="Supprimer">
                              <IconButton
                                color="error"
                                onClick={() => deleteConsult(item.id)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="col pt-2 p-3 pb-0 border">
                <div className="row row-cols-5 d-flex align-items-center pt-2 pb-2">
                  <div className="col-6">
                    <div className=" bg-light">
                      <h5 className="m-0">
                        {nbOrdoOrpheline}
                        {nbOrdoOrpheline > 1
                          ? " Ordonnances Orphelines"
                          : " Ordonnance Orpheline"}
                      </h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="bg-light p-2">
                      <LoadingButton
                        onClick={handleClickOpenOrdoOrph()}
                        endIcon={<SendIcon />}
                        variant="contained"
                      >
                        Ajouter Ordonnance Orpheline
                      </LoadingButton>
                    </div>
                  </div>
                </div>{" "}
                {dataOrdoOrpheline &&
                  Object.keys(dataOrdoOrpheline).length > 0 && (
                    <div className="pt-2 bg-light">
                      {dataOrdoOrpheline.map((item, i) => (
                        <div
                          key={i}
                          className="row row-cols-5 mt-2 mb-2 border d-flex align-items-center justify-content-center"
                        >
                          <div className="col-4">
                            <div className="bg-light">
                              <Link
                                className="text-start"
                                to={`/ordo-orpheline/${item.id}`}
                              >
                                OrdoOrpheline : {item.id}
                              </Link>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <strong>Type</strong> {parse(item.type)}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <Tooltip
                                arrow
                                placement="right"
                                title="Supprimer"
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => deleteordoOrpheline(item.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        <Dialog
          fullScreen
          open={open}
          onClose={handleCloseConsult}
          scroll={scroll}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseConsult}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                align="center"
              >
                Nouvelle Consultation
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent dividers={scroll === "paper"}>
            <ConsultationAdd patient={data} />
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={openOrdoOrph}
          onClose={handleCloseOrdoOrph}
          scroll={scroll}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseOrdoOrph}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                align="center"
              >
                Nouvelle Ordonnance Orpheline
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent dividers={scroll === "paper"}>
            <OrdoOrphelineAdd patient={data.id} />
          </DialogContent>
        </Dialog>
      </div>
    )
  );
};

export default Patient;
