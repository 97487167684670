import { url, reqInstance } from "../../common/authHeader";

export function requestGetOrdoOrpheline() {
  return reqInstance.get(`${url}ordo_orphelines?order[id]=desc`);
}

export function requestGetOneOrdoOrpheline(id) {
  return reqInstance.get(`${url}ordo_orphelines/${id}`);
}

export function requestAddOrdoOrpheline(data) {
  return reqInstance.post(`${url}add-ordoorpheline`, data);
}

export function requestEditOrdoOrpheline(id, data) {
  return reqInstance.put(`${url}ordo_orphelines/${id}`, data);
}

export function requestDeleteOrdoOrpheline(id) {
  return reqInstance.delete(`${url}ordo_orphelines/${id}`);
}
