/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: "patient",
  initialState: { loading: false, data: {}, add: { loading: false } },
  reducers: {
    getPatient(state) {
      return { ...state, data: {}, erreur: null, loading: true };
    },
    setPatient(state, action) {
      const userData = action.payload;
      return {
        ...state,
        success: true,
        data: userData,
        erreur: null,
        loading: false,
      };
    },
    getPatientFailure(state, action) {
      const erreur = action.payload;
      return { ...state, success: false, erreur: erreur, loading: false };
    },
    addPatient() {
      return { add: { loading: true } };
    },
    getOnePatient(state) {
      return { ...state, data: {}, erreur: null, dataOne: {} };
    },
    setOnePatient(state, action) {
      const data = action.payload;
      return { ...state, success: true, data: {}, erreur: null, dataOne: data };
    },
    addPatientSuccess(state, action) {
      const data = action.payload;
      return { add: { loading: false, success: true, data: data } };
    },
    addPatientFailure(state, action) {
      const data = action.payload;
      return {
        ...state,
        erreur: data,
        loading: false,
        add: { loading: false, success: false },
      };
    },
    editPatient(state) {
      return { ...state };
    },
    editPatientSuccess(state) {
      return { ...state, success: true };
    },
    editPatientFailure(state) {
      return {
        ...state,
        success: false,
      };
    },
    deletePatient(state) {
      return { ...state };
    },
    deletePatientSuccess(state) {
      return { ...state, success: true };
    },
    deletePatientFailure(state) {
      return { ...state, success: false };
    },
  },
});
export const {
  getPatient,
  setPatient,
  getPatientFailure,
  addPatient,
  addPatientSuccess,
  addPatientFailure,
  deletePatient,
  deletePatientFailure,
  deletePatientSuccess,
  editPatient,
  editPatientFailure,
  editPatientSuccess,
  getOnePatient,
  setOnePatient,
} = patientSlice.actions;

export default patientSlice.reducer;
