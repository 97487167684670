import { call, put } from "redux-saga/effects";
import {
  setPatient,
  getPatientFailure,
  addPatientSuccess,
  addPatientFailure,
  deletePatientSuccess,
  deletePatientFailure,
  editPatientSuccess,
  editPatientFailure,
  setOnePatient,
} from "../../ducks/patientSlice";
import { setMessage } from "../../ducks/messageSlice";
import {
  requestGetPatient,
  requestAddPatient,
  requestDeletePatient,
  requestEditPatient,
  requestGetOnePatient,
} from "../requests/patient";

export function* handleGetPatient() {
  try {
    const response = yield call(requestGetPatient);
    const { data } = response;
    yield put(setPatient({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getPatientFailure(message));
  }
}

export function* handleAddPatient(action) {
  try {
    const response = yield call(requestAddPatient, action.payload);
    const { data } = response;
    yield put(setMessage("Patient ajouter !!"));
    yield put(addPatientSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(addPatientFailure(message));
  }
}

export function* handleGetOnePatient(action) {
  try {
    const response = yield call(requestGetOnePatient, action.payload);
    const { data } = response;
    yield put(setOnePatient({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getPatientFailure(message));
  }
}

export function* handleEditPatient(action) {
  try {
    const response = yield call(
      requestEditPatient,
      action.payload.id,
      action.payload.data
    );
    const { data } = response;
    yield put(setMessage("Patient modifier !!"));
    yield put(editPatientSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(editPatientFailure(message));
    yield put(setMessage(message));
  }
}
export function* handleDeletePatient(action) {
  try {
    const response = yield call(requestDeletePatient, action.payload);
    const { data } = response;
    yield put(setMessage("Patient supprimer !!"));
    yield put(deletePatientSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(deletePatientFailure(message));
    yield put(setMessage(message));
  }
}
