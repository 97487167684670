import { url, reqInstance } from "../../common/authHeader";

export function requestGetOrdonnance() {
  return reqInstance.get(`${url}ordonnances?order[id]=desc`);
}

export function requestGetOneOrdonnance(id) {
  return reqInstance.get(`${url}ordonnances/${id}`);
}

export function requestAddOrdonnance(data) {
  return reqInstance.post(`${url}add-ordonnance`, data);
}

export function requestEditOrdonnance(id, data) {
  return reqInstance.put(`${url}ordonnances/${id}`, data);
}

export function requestDeleteOrdonnance(id) {
  return reqInstance.delete(`${url}ordonnances/${id}`);
}
