import { call, put } from "redux-saga/effects";
import {
  setOrdonnance,
  getOrdonnanceFailure,
  addOrdonnanceSuccess,
  addOrdonnanceFailure,
  deleteOrdonnanceSuccess,
  deleteOrdonnanceFailure,
  editOrdonnanceSuccess,
  editOrdonnanceFailure,
  setOneOrdonnance,
} from "../../ducks/ordonnanceSlice";
import { setMessage } from "../../ducks/messageSlice";
import {
  requestGetOrdonnance,
  requestAddOrdonnance,
  requestDeleteOrdonnance,
  requestEditOrdonnance,
  requestGetOneOrdonnance,
} from "../requests/ordonnance";

export function* handleGetOrdonnance() {
  try {
    const response = yield call(requestGetOrdonnance);
    const { data } = response;
    yield put(setOrdonnance({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getOrdonnanceFailure(message));
  }
}
export function* handleGetOneOrdonnance(action) {
  try {
    const response = yield call(requestGetOneOrdonnance, action.payload);
    const { data } = response;
    yield put(setOneOrdonnance({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(getOrdonnanceFailure(message));
  }
}
export function* handleAddOrdonnance(action) {
  try {
    const response = yield call(requestAddOrdonnance, action.payload);
    const { data } = response;
    yield put(setMessage("Ordonnance ajouter !!"));
    yield put(addOrdonnanceSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(setMessage(message));
    yield put(addOrdonnanceFailure(message));
  }
}

export function* handleEditOrdonnance(action) {
  try {
    const response = yield call(
      requestEditOrdonnance,
      action.payload.id,
      action.payload.data
    );
    const { data } = response;
    yield put(setMessage("Ordonnance modifier !!"));
    yield put(editOrdonnanceSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(editOrdonnanceFailure(message));
    yield put(setMessage(message));
  }
}
export function* handleDeleteOrdonnance(action) {
  try {
    const response = yield call(requestDeleteOrdonnance, action.payload);
    const { data } = response;
    yield put(setMessage("Ordonnance supprimer !!"));
    yield put(deleteOrdonnanceSuccess({ ...data }));
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    yield put(deleteOrdonnanceFailure(message));
    yield put(setMessage(message));
  }
}
