import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { url, reqInstance } from "../../common/authHeader";
import dayjs from "dayjs";
import { Formik, Field, Form } from "formik";

import relativeTime from "dayjs/plugin/relativeTime.js";
dayjs.extend(relativeTime);
import { getOnePatient } from "../../ducks/patientSlice";
import {
  clearAddConsultation,
  deleteConsultation,
} from "../../ducks/consultationSlice";
import { deleteOrdoOrpheline } from "../../ducks/ordoOrphelineSlice";
import { clearMessage } from "../../ducks/messageSlice";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ConsultationAdd from "../Consultation/ConsultationAdd";
import { DialogContent, TextField, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import OrdoOrphelineAdd from "../OrdoOrpheline/OrdoOrphelineAdd";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Button from "@mui/material/Button";

const Visite = (props) => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  //const { role } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [role, setRole] = useState("ROLE_ADMIN");
  //const [payed, setPayed] = useState(0);
  //const [status, setStatus] = useState(0);

  useEffect(() => {
    dispatch(clearMessage());
    getData();
    console.log(data);
  }, []);

  const getData = () => {
    let today = dayjs().format("YYYY-MM-DD");
    console.log(today);
    reqInstance
      .get(`${url}visites?order[id]&dateVisite[after]=${today}`)
      .then((res) => {
        setData(res.data);

        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePayed = (e, id) => {
    console.log(e.target.value);
    console.log(id);

    const payed = e.target.value;
    const itemId = data.filter((item) => item.id === id);
    console.log(itemId);
    console.log(itemId["0"].prix);
    const reste = itemId["0"].prix - e.target.value;
    const status = itemId["0"].prix == payed ? true : false;

    const newArr = data.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          reste: reste,
          payed: parseInt(e.target.value),
          status: status,
        };
      }
      return obj;
    });
    setData(newArr);
    console.log(newArr);
  };

  const initialValues = {
    reste: "",
    payed: "",
    prix: "",
  };
  const handleVisitePut = (id, formValue) => {
    console.log(formValue);
    const itemId = data.filter((item) => item.id === id);
    console.log(itemId);
    let reste = itemId["0"].prix > 0 ? itemId["0"].reste : formValue.prix;
    console.log(reste);
    const formData = {
      ...formValue,
      payed: itemId["0"].payed,
      reste: reste,
      status: itemId["0"].status,
    };
    console.log(formData);

    reqInstance
      .put(`${url}visites/${id}`, formData)
      .then((res) => {
        const newArr = data.map((obj) => {
          if (obj.id === id) {
            return {
              ...obj,
              reste: res.data.reste,
            };
          }
          return obj;
        });
        console.log(res.data);
        setData(newArr);
      })
      .catch((error) => {
        console.log(error);
      });

    if (formData.status === true) {
      const patientUpdate = {
        typeVisite: itemId["0"].type,
        lastVisite: itemId["0"].dateVisite,
        status: itemId["0"].status,
      };
      console.log(patientUpdate);
      reqInstance
        .put(`${url}patients/${itemId["0"].patient.id}`, patientUpdate)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <h1 className="text-center mb-4">Visites du jour</h1>
      {data && (
        <div className="col-md-12">
          {message && (
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          )}
        </div>
      )}

      {data &&
        data.map((item, i) => (
          <Formik
            key={i}
            initialValues={{
              reste: item.item,
              prix: item.prix,
              type: item.type,
            }}
            onSubmit={(formValue) => handleVisitePut(item.id, formValue)}
          >
            <Form>
              <div
                style={
                  item.status === true && item.reste == 0
                    ? { backgroundColor: "#a6e9d5" }
                    : item.status === false && item.payed > 0
                    ? { backgroundColor: "#f1aeb5" }
                    : { backgroundColor: "transparent" }
                }
                className="row g-2 mt-2 mb-2 border d-flex align-items-center justify-content-center"
              >
                <div className="col mt-0 pb-2 pt-2">
                  <div className="form-floating">
                    <strong>Patient : </strong>
                    <Link to={`${url}patient/${item.patient.id}`}>
                      {item.patient.nom} {item.patient.prenom}
                    </Link>
                  </div>
                </div>
                <div className="col mt-0 pb-2 pt-2">
                  <div className="form-floating">
                    <Field
                      name="prix"
                      type="number"
                      id="floatingInput"
                      className="form-control"
                      placeholder="prix"
                      readOnly={role == "ROLE_ADMIN" ? false : true}
                    />
                    <label htmlFor="floatingInput">Prix</label>
                  </div>
                </div>
                <div className="col mt-0 pb-2 pt-2">
                  <div className="form-floating">
                    <Field
                      name="payed"
                      type="number"
                      id="floatingInput"
                      className="form-control"
                      value={item.payed}
                      onChange={(e) => changePayed(e, item.id)}
                    />
                    <label htmlFor="floatingInput">Reçu</label>
                  </div>
                </div>
                <div className="col mt-0 pb-2 pt-2">
                  <div className="form-floating">
                    <Field
                      name="reste"
                      type="number"
                      id="floatingInput"
                      className="form-control"
                      value={item.reste}
                      onChange={(e) => changePayed(e, item.id)}
                      readOnly
                    />
                    <label htmlFor="floatingInput">Reste</label>
                  </div>
                </div>
                <div className="col mt-0 pb-2 pt-2">
                  <div className="form-floating">
                    <Field
                      name="type"
                      type="text"
                      id="floatingInput"
                      className="form-control"
                    />
                    <label htmlFor="floatingInput">Visite</label>
                  </div>
                </div>
                <div className="col mt-0 pb-2 pt-2">
                  <div className="form-group">
                    <LoadingButton
                      type="submit"
                      size="small"
                      endIcon={<SendIcon />}
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                    >
                      Envoyer
                    </LoadingButton>
                  </div>
                </div>
                {/*   <TextField
                  id="prix"
                  label="Prix"
                  name="prix"
                  value={item.prix}
                  InputProps={{
                    readOnly: false,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
                // <TextField
                //   id="payed"
                //   label="Payed"
                //   type="number"
                //   defaultValue={item.payed}
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                //   size="small"
                //   onChange={(e) => changePayed(e, item.id)}
                // />
                // <TextField
                //   id="reste"
                //   label="Reste"
                //   value={item.reste}
                //   InputProps={{
                //     readOnly: true,
                //   }}
                //   InputLabelProps={{
                //     shrink: true,
                //   }}
                //   size="small"
                // /> */}
              </div>
            </Form>
          </Formik>
        ))}
    </>
  );
};

Visite.propTypes = {
  patient: PropTypes.number,
};
export default Visite;
