import React from "react";
import { createRoot } from "react-dom/client";
//import './index.css';
import dayjs from "dayjs";
import "dayjs/locale/fr";
dayjs.locale("fr");
import App from "../src/containers/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import decode from "jwt-decode";
import { loginUserSuccess, logOut } from "../src/ducks/userSlice";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const container = document.getElementById("root");
const root = createRoot(container);

if (localStorage.userJWT) {
  const payload = decode(localStorage.userJWT);
  const timestamp = Math.floor(Date.now() / 1000);
  const expire = payload.exp;
  const delay = (expire - timestamp) * 1000;

  // state to share in app exanmple : isAuthenticated
  const user = payload.roles["0"];
  store.dispatch(loginUserSuccess(user));
  /*   console.log(payload);
  console.log(expire);
  setTimeout(() => {
    console.log(delay);
    localStorage.removeItem("userJWT");
    history.push("/");
    store.dispatch(logOut(null));
    location.reload();
  }, delay); */
}

root.render(    
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </LocalizationProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
//registerServiceWorker();
