import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { setMessage, clearMessage } from "../../ducks/messageSlice";
import { url, reqInstance } from "../../common/authHeader.js";
import { Fragment } from "react";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  getOneOrdoOrpheline,
  addOrdoOrpheline,
  resetOrdoOrpheline,
} from "../../ducks/ordoOrphelineSlice";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import { tiny } from "../../common/Consts";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

const OrdoOrphelineAdd = (props) => {
  const { addordoOrpheline } = useSelector((state) => state.ordoOrpheline);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [ordoOrphelineId, setOrdoOrphelineId] = useState(null);
  const [medList, setMedList] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [editorData, setEditorData] = useState([]);

  const handleEditorChange = (value, editor, e) => {
    const data = {
      ...editorData,
      [editor.id]: tinymce.get(editor.id).getContent(),
    };
    setEditorData(data);
  };

  const handleTexteAdd = (formValue) => {
    const { texte } = editorData;
    const dataF = {
      ...formValue,
      patient: props.patient,
      type: "texte",
      texte,
    };
    dispatch(addOrdoOrpheline(dataF));
  };

  const handleCertificatAdd = (formValue) => {
    const { certificat } = editorData;
    const dataF = {
      ...formValue,
      patient: props.patient,
      type: "certificat",
      certificat,
    };
    dispatch(addOrdoOrpheline(dataF));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(resetOrdoOrpheline());
    dispatch(clearMessage());
  };

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    patient: props.patient,
    nom: "",
    posologie: "",
    duree: "",
  };
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Ce champ est obligatoire"),
  });

  const handlClick = (formValue) => {
    dispatch(clearMessage());
    const formData = {
      ...formValue,
      ordoOrpheline: ordoOrphelineId,
    };
    reqInstance
      .post(`${url}add-medicament-ordo-orpheline`, formData)
      .then((res) => {
        setMedList(res.data);
        const newMedList = [...medList, res.data];
        setMedList(newMedList);
        setOrdoOrphelineId(res.data.ordoOrpheline);
        dispatch(getOneOrdoOrpheline(res.data.ordoOrpheline));
      })
      .catch((err) => {
        dispatch(setMessage(err.message));
      });
  };

  const handelDelete = (id) => {
    setMedList(medList.filter((item) => item.id !== id));
    reqInstance
      .delete(`${url}medicaments/${id}`)
      .then((res) => {
        //console.log(res.data);
      })
      .catch((err) => {
        dispatch(setMessage(err.message));
      });
  };

  return (
    <Fragment>
      <div className="container overflow-hidden">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ ml: 2, flex: 1, color: "text.secondary" }}
              variant="h6"
              align="center"
            >
              Ajouter Médicament
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {message && addordoOrpheline && (
              <div className="form-group">
                {addordoOrpheline.success === true ? (
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div>
                ) : (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                )}
              </div>
            )}
            {medList.map((item, i) => (
              <div
                key={i}
                className="row row-cols-5 border row row-cols-sm-4 mb-3"
              >
                <div className="col">
                  <div className="bg-light p-2">
                    <strong>Médicament</strong>
                    <p className="mb-0">{item.nom}</p>
                  </div>
                </div>
                <div className="col ">
                  <div className="bg-light p-2">
                    <strong>Posologie</strong>
                    <p className="mb-0">{item.posologie}</p>
                  </div>
                </div>
                <div className="col ">
                  <div className="bg-light p-2">
                    <strong>Durée</strong>
                    <p className="mb-0">{item.duree}</p>
                  </div>
                </div>
                <div className="col ">
                  <div className="bg-light p-2">
                    <IconButton
                      color="error"
                      onClick={() => handelDelete(item.id)}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handlClick}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="row g-12">
                    <div className="col-md-2">
                      <div className="form-floating mb-3">
                        <Field
                          name="nom"
                          type="text"
                          id="floatingInput"
                          className={
                            errors.nom && touched.nom
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="nom"
                        />
                        <label htmlFor="floatingInput">Nom</label>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-floating mb-3">
                        <Field
                          name="posologie"
                          type="text"
                          id="floatingInput"
                          className={
                            errors.posologie && touched.posologie
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="posologie"
                        />
                        <label htmlFor="floatingInput">Posologie</label>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="form-floating mb-3">
                        <Field
                          name="duree"
                          type="text"
                          id="floatingInput"
                          className="form-control"
                          placeholder="durée"
                        />
                        <label htmlFor="floatingInput">Durée</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group pb-2">
                    <LoadingButton
                      type="submit"
                      endIcon={<SendIcon />}
                      variant="contained"
                    >
                      Ajouter
                    </LoadingButton>
                  </div>
                </Form>
              )}
            </Formik>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              sx={{ ml: 2, flex: 1, color: "text.secondary" }}
              variant="h6"
              align="center"
            >
              Ajouter Texte
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Formik
              initialValues={{
                patient: props.patient,
              }}
              onSubmit={handleTexteAdd}
            >
              <Form>
                <div className="row g-12">
                  <div className="col-md-4">
                    <label className="form-label">Texte</label>
                    <Editor
                      init={tiny}
                      id="texte"
                      value={editorData.texte}
                      onEditorChange={handleEditorChange}
                    />
                  </div>
                </div>

                <div className="form-group mt-4">
                  <LoadingButton
                    type="submit"
                    endIcon={<SendIcon />}
                    color={
                      addordoOrpheline && addordoOrpheline.success === true
                        ? "success"
                        : addordoOrpheline.success === false
                        ? "error"
                        : "primary"
                    }
                    disabled={addordoOrpheline.success}
                    variant="contained"
                    loading={addordoOrpheline.loading}
                  >
                    Ajouter
                  </LoadingButton>
                </div>

                {message && addordoOrpheline && (
                  <div className="form-group mt-2">
                    {addordoOrpheline.success === true ? (
                      <div className="alert alert-success" role="alert">
                        {message}
                      </div>
                    ) : (
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    )}
                  </div>
                )}
              </Form>
            </Formik>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography
              sx={{ ml: 2, flex: 1, color: "text.secondary" }}
              variant="h6"
              align="center"
            >
              Ajouter un Certificat
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Formik
              initialValues={{
                patient: props.patient,
              }}
              onSubmit={handleCertificatAdd}
            >
              <Form>
                <div className="row g-12">
                  <div className="col-md-4">
                    <label className="form-label">Certificat</label>
                    <Editor
                      init={tiny}
                      id="certificat"
                      value={editorData.certificat}
                      onEditorChange={handleEditorChange}
                    />
                  </div>
                </div>
                <div className="form-group mt-4">
                  <LoadingButton
                    type="submit"
                    endIcon={<SendIcon />}
                    color={
                      addordoOrpheline && addordoOrpheline.success === true
                        ? "success"
                        : addordoOrpheline.success === false
                        ? "error"
                        : "primary"
                    }
                    disabled={addordoOrpheline.success}
                    variant="contained"
                    loading={addordoOrpheline.loading}
                  >
                    Ajouter
                  </LoadingButton>
                </div>
                {message && addordoOrpheline && (
                  <div className="form-group mt-2">
                    {addordoOrpheline.success === true ? (
                      <div className="alert alert-success" role="alert">
                        {message}
                      </div>
                    ) : (
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    )}
                  </div>
                )}
              </Form>
            </Formik>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  );
};

OrdoOrphelineAdd.propTypes = {
  patient: PropTypes.number,
};

export default OrdoOrphelineAdd;
