import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { clearMessage } from "../../ducks/messageSlice";
import { addConsultation } from "../../ducks/consultationSlice";

import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import { tiny } from "../../common/Consts";
import OrdonnanceAdd from "../Ordonnance/OrdonnanceAdd.js";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const ConsultationAdd = (props) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const { addconsult } = useSelector((state) => state.consultation);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [editorData, setEditorData] = useState([]);

  const handleClickOpen = () => () => {
    setOpen(true);
    scroll;
  };
  const handleClose = () => {
    setOpen(false);
  };

  //const id = lastPart(location.pathname);
  const patient = props.patient;
  const initialValues = {
    patient: patient.id,
    tension: "",
    temperature: "",
    poids: "",
    motif: "",
    traitement_en_cours: "",
    atcd: "",
    diagnostic: "",
    cat: "",
    prix: "",
  };
  // const initialValues = {
  //   "patient": patient.id,
  //   "tension": "",
  //   "temperature": "",
  //   "poids": "",
  //   "motif": "",
  //   "traitement_en_cours": "",
  //   "atcd": "",
  //   "diagnostic": "",
  //   "cat": "",
  //   "prix": ""
  // };

  const validationSchema = Yup.object().shape({
    poids: Yup.string().required("Ce champ est obligatoire"),
    tension: Yup.string().required("Ce champ est obligatoire"),
    temperature: Yup.string().required("Ce champ est obligatoire"),
  });

  const handleEditorChange = (value, editor, e) => {
    const data = {
      ...editorData,
      [editor.id]: tinymce.get(editor.id).getContent(),
    };
    setEditorData(data);
  };

  const handleConsultationAdd = (formValue) => {
    //const { motif, traitement_en_cours, cat, diagnostic, atcd } = editorData
    const motif = "rrrr";
    const traitement_en_cours = "rrrr";
    const atcd = "rrrr";
    const diagnostic = "rrrr";
    const cat = "ll";
    const dataF = {
      ...formValue,
      motif,
      traitement_en_cours,
      cat,
      diagnostic,
      atcd,
    };
    //console.log(dataF)
    dispatch(addConsultation(dataF));
  };

  return (
    <Fragment>
      <div className="card-container">
        <Formik
          initialValues={initialValues}
          //validationSchema={validationSchema}
          onSubmit={handleConsultationAdd}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row g-12">
                <div className="col-md-2">
                  <div className="form-floating mb-3">
                    <Field
                      name="tension"
                      type="text"
                      id="floatingInput"
                      className={
                        errors.tension && touched.tension
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="tension"
                    />
                    <label htmlFor="floatingInput">Tension</label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-floating mb-3">
                    <Field
                      name="poids"
                      type="text"
                      id="floatingInput"
                      className={
                        errors.poids && touched.poids
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="poids"
                    />
                    <label htmlFor="floatingInput">Poids</label>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-floating mb-3">
                    <Field
                      name="temperature"
                      type="text"
                      id="floatingInput"
                      className={
                        errors.temperature && touched.temperature
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="temperature"
                    />
                    <label htmlFor="floatingInput">Température</label>
                  </div>
                </div>
              </div>

              <div className="row g-12">
                <div className="col-md-4">
                  <label className="form-label">Motif</label>
                  <Editor
                    init={tiny}
                    id="motif"
                    value={editorData.motif}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Traitement en cours</label>
                  <Editor
                    init={tiny}
                    id="traitement_en_cours"
                    value={editorData.traitement_en_cours}
                    onEditorChange={handleEditorChange}
                  />
                </div>
              </div>

              <div className="row g-12">
                <div className="col-md-4 gy-4">
                  <label className="form-label">Atcd</label>
                  <Editor
                    init={tiny}
                    id="atcd"
                    value={editorData.atcd}
                    onEditorChange={handleEditorChange}
                  />
                </div>

                <div className="col-md-4 gy-4">
                  <label className="form-label">Diagnostic</label>
                  <Editor
                    init={tiny}
                    id="diagnostic"
                    value={editorData.diagnostic}
                    onEditorChange={handleEditorChange}
                  />
                </div>
              </div>

              <div className="row g-12 mb-3">
                <div className="col-md-4 gy-4">
                  <label className="form-label">Cat</label>
                  <Editor
                    init={tiny}
                    id="cat"
                    value={editorData.cat}
                    onEditorChange={handleEditorChange}
                  />
                </div>

                <div className="col-md-4 gy-4">
                  <div className="form-floating mb-3">
                    <Field
                      name="prix"
                      type="number"
                      id="floatingInput"
                      className={
                        errors.prix && touched.prix
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="prix"
                    />
                    <label htmlFor="floatingInput">Prix</label>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className={
                    addconsult.success === true
                      ? "btn btn-success"
                      : addconsult.success === false
                      ? "btn btn-danger"
                      : "btn btn-primary"
                  }
                  disabled={addconsult.success}
                >
                  {addconsult.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Ajouter</span>
                </button>
              </div>

              <div className="form-group mt-3">
                {addconsult.success === true && (
                  <Button
                    className="btn btn-primary"
                    onClick={handleClickOpen()}
                  >
                    Ajouter Ordonnance
                  </Button>
                )}
              </div>

              {message && (
                <div className="form-group mt-2">
                  {addconsult.success === true ? (
                    <div className="alert alert-success" role="alert">
                      {message}
                    </div>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <Dialog fullScreen open={open} onClose={handleClose} scroll={scroll}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Nouvelle Ordonnance
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={scroll === "paper"}>
          <OrdonnanceAdd
            consultation={
              addconsult.success == true ? addconsult.data.id : null
            }
            controle={null}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

ConsultationAdd.propTypes = {
  patient: PropTypes.object,
};

export default ConsultationAdd;
