import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { clearMessage } from "../../ducks/messageSlice";
import { addControle } from "../../ducks/controleSlice";
import { resetOrdonnance } from "../../ducks/ordonnanceSlice";
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce";
import { tiny } from "../../common/Consts";
import OrdonnanceAdd from "../Ordonnance/OrdonnanceAdd.js";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const ControleAdd = (props) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const handleClickOpen = () => () => {
    setOpen(true);
    scroll;
    dispatch(clearMessage());
    dispatch(resetOrdonnance());
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { addcontrole } = useSelector((state) => state.controle);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [editorData, setEditorData] = useState([]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    evolution: "",
    cat: "",
  };

  const handleEditorChange = (value, editor, e) => {
    const data = {
      ...editorData,
      [editor.id]: tinymce.get(editor.id).getContent(),
    };
    setEditorData(data);
  };

  const handleControleAdd = (formValue) => {
    const { evolution, cat } = editorData;
    const dataF = {
      ...formValue,
      consultation: props.consultation,
      evolution,
      cat,
    };
    dispatch(addControle(dataF));
  };

  return (
    <Fragment>
      <div className="card-container">
        <Formik initialValues={initialValues} onSubmit={handleControleAdd}>
          <Form>
            <div className="row g-12">
              <div className="col-md-4">
                <label className="form-label">Evolution</label>
                <Editor
                  init={tiny}
                  id="evolution"
                  value={editorData.evolution}
                  onEditorChange={handleEditorChange}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label">Cat</label>
                <Editor
                  init={tiny}
                  id="cat"
                  value={editorData.cat}
                  onEditorChange={handleEditorChange}
                />
              </div>
            </div>

            <div className="form-group mt-4">
              <button
                type="submit"
                className={
                  addcontrole.success === true
                    ? "btn btn-success"
                    : addcontrole.success === false
                    ? "btn btn-danger"
                    : "btn btn-primary"
                }
                disabled={addcontrole.success}
              >
                {addcontrole.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span> Ajouter</span>
              </button>
            </div>

            <div className="form-group mt-3">
              {addcontrole.success === true && (
                <Button className="btn btn-primary" onClick={handleClickOpen()}>
                  Ajouter Ordonnance
                </Button>
              )}
            </div>

            {message && (
              <div className="form-group mt-2">
                {addcontrole.success === true ? (
                  <div className="alert alert-success" role="alert">
                    {message}
                  </div>
                ) : (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                )}
              </div>
            )}
          </Form>
        </Formik>
      </div>

      <Dialog fullScreen open={open} onClose={handleClose} scroll={scroll}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Nouvelle Ordonnance
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={scroll === "paper"}>
          <OrdonnanceAdd
            controle={addcontrole ? addcontrole.data.id : null}
            consultation={null}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

ControleAdd.propTypes = {
  consultation: PropTypes.number,
};

export default ControleAdd;
