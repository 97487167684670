import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { watcherSaga } from "./sagas/rootSaga";
import userReducer from "./ducks/userSlice";
import patientReducer from "./ducks/patientSlice";
import messageReducer from "./ducks/messageSlice";
import consultationReducer from "./ducks/consultationSlice";
import controleReducer from "./ducks/controleSlice";
import ordonnanceReducer from "./ducks/ordonnanceSlice";
import ordoOrphelineReducer from "./ducks/ordoOrphelineSlice";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  user: userReducer,
  patient: patientReducer,
  consultation: consultationReducer,
  controle: controleReducer,
  ordonnance: ordonnanceReducer,
  ordoOrpheline: ordoOrphelineReducer,
  message: messageReducer,
});

const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({ thunk: false }).concat(sagaMiddleware),
});
sagaMiddleware.run(watcherSaga);
export default store;
