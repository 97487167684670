import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Routes } from "react-router-dom";
import "../../index.css";
import Dropdown from "react-bootstrap/Dropdown";
import Home from "../../components/Home";
import Login from "../../components/Login";
import { logOut } from "../../ducks/userSlice";
import history from "../../history";
import Consultation from "../Consultation/Consultation.js";
import ConsultationAdd from "../Consultation/ConsultationAdd";
import ConsultationList from "../Consultation/ConsultationList";
import Patient from "../Patient/Patient.js";
import PatientAdd from "../Patient/PatientAdd";
import PatientList from "../Patient/PatientList";
import Ordonnance from "../Ordonnance/Ordonnance.js";
import OrdonnanceAdd from "../Ordonnance/OrdonnanceAdd.js";
import Controle from "../Controle/Controle";
import OrdoOrpheline from "../OrdoOrpheline/OrdoOrpheline.js";
import OrdoOrphelineAdd from "../OrdoOrpheline/OrdoOrphelineAdd.js";
import Visite from "../Visite/Visite.js";
import VisiteAdd from "../Visite/VisiteAdd.js";

const App = () => {
  const { isLoggedIn, role } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const roles = role ? role.includes("ROLE_ADMIN") : null;
  const admin = roles ? true : false;

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          bezKoder
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li>{" "}
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Patient
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href={"/patients"}>List Patients</Dropdown.Item>
              <Dropdown.Item href={"/add-patient"}>
                Ajouter Patient
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Consultation
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href={"/consultations"}>
                List Consultation
              </Dropdown.Item>
              <Dropdown.Item href={"/add-consultation/"}>
                Ajouter Consultation
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Visite
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href={"/visite"}>List Visite</Dropdown.Item>
              <Dropdown.Item href={"/add-visite/"}>
                Ajouter Visite
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <li className="nav-item">
            <Link to={"/profile"} className="nav-link">
              Profile
            </Link>
          </li>
          {!admin && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                user Board
              </Link>
            </li>
          )}
          {admin && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )}
          {admin && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )}
        </div>

        {isLoggedIn ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {"user"}
              </Link>
            </li>
            <li className="nav-item">
              <a
                href=""
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(logOut());
                }}
              >
                LogOut
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes history={history}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/add-patient" element={<PatientAdd />} />
          <Route path="/patients" element={<PatientList />} />
          <Route path="/patient/:id" element={<Patient />} />
          <Route path="/add-consultation/:id" element={<ConsultationAdd />} />
          <Route path="/consultations" element={<ConsultationList />} />
          <Route path="/consultation/:id" element={<Consultation />} />
          <Route path="/controle/:id" element={<Controle />} />
          <Route path="/ordonnance/:id" element={<Ordonnance />} />
          <Route path="/add-ordonnance" element={<OrdonnanceAdd />} />
          <Route path="/ordo-orpheline/:id" element={<OrdoOrpheline />} />
          <Route path="/add-ordo-orpheline" element={<OrdoOrphelineAdd />} />
          <Route path="/visite" element={<Visite />} />
          <Route path="/add-visite" element={<VisiteAdd />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
