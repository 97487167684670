import { url, reqInstance } from "../../common/authHeader";

export function requestGetControle() {
  return reqInstance.get(`${url}controles?order[id]=desc`);
}

export function requestGetOneControle(id) {
  return reqInstance.get(`${url}controles/${id}`);
}

export function requestAddControle(data) {
  return reqInstance.post(`${url}add-controle`, data);
}

export function requestEditControle(id, data) {
  return reqInstance.put(`${url}controles/${id}`, data);
}

export function requestDeleteControle(id) {
  return reqInstance.delete(`${url}controles/${id}`);
}
