import React, { useState, useEffect } from "react";
import { url, reqInstance } from "../../common/authHeader.js";
import { lastPart } from "../../common/Fonction.js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import parse from "html-react-parser";
dayjs.extend(relativeTime);
import { setMessage, clearMessage } from "../../ducks/messageSlice";
import { addControleClear } from "../../ducks/controleSlice";
import { getOneConsultation } from "../../ducks/consultationSlice";
import { resetOrdonnance } from "../../ducks/ordonnanceSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ControleAdd from "../Controle/ControleAdd";
import { DialogContent, Tooltip } from "@mui/material";
import OrdonnanceAdd from "../Ordonnance/OrdonnanceAdd.js";
import { Delete } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

const Consultation = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [openControle, setOpenControle] = useState(false);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { addcontrole } = useSelector((state) => state.controle);
  const { dataOneConslt } = useSelector((state) => state.consultation);
  const { addordonnance } = useSelector((state) => state.ordonnance);
  const [data, setData] = useState([]);
  const [age, setAge] = useState("");
  const [nbOrdonnance, setNbOrdonnance] = useState(0);
  const [nbControle, setNbControle] = useState(0);
  const [dataOrdonnance, setDataOrdonnance] = useState([]);
  const [dataControle, setDataControle] = useState([]);
  const id = lastPart(location.pathname);

  const handleClickOpen = () => () => {
    setOpen(true);
    scroll;
    dispatch(resetOrdonnance());
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(getOneConsultation(id));
  };
  const handleClickOpenControle = () => () => {
    setOpenControle(true);
    scroll;
  };
  const handleCloseControle = () => {
    setOpenControle(false);
    dispatch(clearMessage());
    dispatch(addControleClear());
    dispatch(getOneConsultation(id));
  };

  const deleteOrdo = (id) => {
    if (!confirm(`Tu veux vraiment supprimer l'ordonnance n°: ${id}`)) {
      return;
    }
    setDataOrdonnance(dataOrdonnance.filter((item) => item.id !== id));
    reqInstance
      .delete(`${url}ordonnances/${id}`)
      .then((res) => {
        setNbOrdonnance(nbOrdonnance - 1);
      })
      .catch((err) => {
        dispatch(setMessage(err.message));
      });
  };

  const deletecontrole = (id) => {
    if (!confirm(`Tu veux vraiment supprimer le controle n°: ${id}`)) {
      return;
    }
    setDataControle(dataControle.filter((item) => item.id !== id));
    reqInstance
      .delete(`${url}controles/${id}`)
      .then((res) => {
        setNbControle(nbControle - 1);
      })
      .catch((err) => {
        dispatch(setMessage(err.message));
      });
  };

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getOneConsultation(id));
  }, [dispatch, id]);

  useEffect(() => {
    setData(dataOneConslt);
    if (dataOneConslt && Object.keys(dataOneConslt).length > 0) {
      setAge(dayjs(dataOneConslt.patient.dateNaissance).toNow(true));
      setNbOrdonnance(Object.keys(dataOneConslt.ordonnances).length);
      setDataOrdonnance(
        [...dataOneConslt.ordonnances].sort((a, b) => b.id - a.id)
      );
      setNbControle(Object.keys(dataOneConslt.controles).length);
      setDataControle([...dataOneConslt.controles].sort((a, b) => b.id - a.id));
    }
  }, [dataOneConslt, data]);

  return (
    <div className="col-md-12 signup-form">
      {message && addordonnance && (
        <div className="form-group">
          {addordonnance.success === true || addcontrole.success === true ? (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          ) : (
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          )}
        </div>
      )}
      {data && data.patient && data.ordonnances && (
        <div>
          <h1 className="text-center mb-4">Consultation n° {data.id}</h1>
          <div className="container overflow-hidden text-center">
            <div className="row row-cols-3 g-4 g-lg-3">
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Nom - Prenom :</strong>
                  </p>
                  <p>
                    {data.patient.nom} {data.patient.prenom}
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <div className="col">
                    <p className="mt-2">
                      <strong>Sexe : </strong>
                      {data.patient.sexe}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <strong> Age : </strong>
                      {age}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Date de consultation :</strong>
                  </p>
                  <p>{dayjs(data.dateConsultation).format("DD-MM-YYYY")}</p>
                </div>
              </div>
              <div className="col">
                <div className="p-3 border bg-light">
                  <strong>Poids</strong> : {data.poids} Kg
                  <br />
                  <strong>Tension</strong> : {data.tension}
                  <br />
                  <strong>Température</strong> : {data.temperature}
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Motif :</strong>
                  </p>
                  {parse(data.motif)}
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Atcd :</strong>
                  </p>
                  {parse(data.atcd)}
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Diagnostic :</strong>
                  </p>
                  {parse(data.diagnostic)}
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Conduite à tenir :</strong>
                  </p>
                  {parse(data.cat)}
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Traitement en cours :</strong>
                  </p>
                  {parse(data.traitementEnCours)}
                </div>
              </div>
              <div className="col"></div>{" "}
              <div className="col">
                <div className="p-2 border bg-light">
                  <strong>Prix</strong> : {data.prix}
                  <br />
                  <strong>Payer</strong> : {data.payed}
                  <br />
                  <strong>Reste</strong> : {data.reste}
                </div>
              </div>
              <div className="col-6">
                <div className="col pt-2 p-3 pb-0 border">
                  <div className="row row-cols-5 align-items-center pt-2 pb-2">
                    <div className="col-6">
                      <div className=" bg-light">
                        <h5 className="m-0">
                          {/* {dataOne && Object.keys(dataOne).length > 0
                            ? nbOrdonnance + 1
                            : */}{" "}
                          {nbOrdonnance}
                          {nbOrdonnance > 1 ? " Ordonnances" : " Ordonnance"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="bg-light p-2">
                        <LoadingButton
                          onClick={handleClickOpen()}
                          endIcon={<SendIcon />}
                          variant="contained"
                        >
                          Ajouter Ordonnance
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                  {Object.keys(dataOrdonnance).length > 0 && (
                    <div className="pt-2 bg-light">
                      {dataOrdonnance.map((item, i) => (
                        <div
                          key={i}
                          className="row row-cols-5 mt-2 mb-2 border d-flex align-items-center justify-content-center"
                        >
                          <div className="col-4">
                            <div className="bg-light">
                              <Link
                                className="text-start"
                                to={`/ordonnance/${item.id}`}
                              >
                                Ordonnance : {item.id}
                              </Link>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <strong>Type</strong> <p>{item.type}</p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <Tooltip
                                arrow
                                placement="right"
                                title="Supprimer"
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => deleteOrdo(item.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="col pt-2 p-3 pb-0 border">
                  <div className="row row-cols-5 d-flex align-items-center pt-2 pb-2">
                    <div className="col-6">
                      <div className=" bg-light">
                        <h5 className="m-0">
                          {nbControle}
                          {nbControle > 1 ? " Controles" : " Controle"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="bg-light p-2">
                        <LoadingButton
                          onClick={handleClickOpenControle()}
                          endIcon={<SendIcon />}
                          variant="contained"
                        >
                          Ajouter Controle
                        </LoadingButton>
                      </div>
                    </div>
                  </div>

                  {Object.keys(dataControle).length > 0 && (
                    <div className="pt-2 bg-light">
                      {dataControle.map((item, i) => (
                        <div
                          key={i}
                          className="row row-cols-5 mt-2 mb-2 border d-flex align-items-center justify-content-center"
                        >
                          <div className="col-4">
                            <div className="bg-light">
                              <Link
                                className="text-start"
                                to={`/controle/${item.id}`}
                              >
                                Controle : {item.id}
                              </Link>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <strong>Evolution</strong> {parse(item.evolution)}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="bg-light">
                              <Tooltip
                                arrow
                                placement="right"
                                title="Supprimer"
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => deletecontrole(item.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Dialog
            fullScreen
            open={openControle}
            onClose={handleCloseControle}
            scroll={scroll}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseControle}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                  align="center"
                >
                  Nouveau Controle
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent dividers={scroll === "paper"}>
              <ControleAdd consultation={data.id} />
            </DialogContent>
          </Dialog>

          <Dialog fullScreen open={open} onClose={handleClose} scroll={scroll}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                  align="center"
                >
                  Nouvelle Ordonnance
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent dividers={scroll === "paper"}>
              <OrdonnanceAdd consultation={data.id} />
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Consultation;
