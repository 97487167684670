import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { clearMessage } from "../../ducks/messageSlice";
import { addPatient } from "../../ducks/patientSlice";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import VisiteAdd from "../Visite/VisiteAdd.js";

const PatientAdd = () => {
  const { add } = useSelector((state) => state.patient);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = () => () => {
    setOpen(true);
    scroll;
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(clearMessage());
  };

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    nom: "",
    prenom: "",
    sexe: "",
    date_naissance: "",
    cin: "",
    adresse: "",
    tel: "",
    couverture_social: "",
    type_visite: "",
  };

  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Ce champ est obligatoire"),
    prenom: Yup.string().required("Ce champ est obligatoire"),
    sexe: Yup.string().required("Ce champ est obligatoire"),
  });

  const [value, setValue] = useState([]);
  const [emptyDate, setEmptyDate] = useState(false);

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };

  const handlePatientAdd = (formValue) => {
    if (value.length == 0) {
      setEmptyDate(true);
    } else {
      setEmptyDate(false);
      const data = {
        ...formValue,
        date_naissance: dayjs(value).format("DD-MM-YYYY"),
      };
      dispatch(addPatient(data));
    }
  };

  return (
    <div className="col-md-12 signup-form">
      {message && (
        <div className="form-group">
          {add.success === true ? (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          ) : (
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          )}
        </div>
      )}
      <div className="card-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlePatientAdd}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row g-12">
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <Field
                      name="prenom"
                      type="text"
                      id="floatingInput"
                      className={
                        errors.prenom && touched.prenom
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Prenom"
                    />
                    <label htmlFor="floatingInput">Prenom</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <Field
                      name="nom"
                      type="text"
                      id="floatingInput"
                      className={
                        errors.nom && touched.nom
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Nom"
                    />
                    <label htmlFor="floatingInput">Nom</label>
                  </div>
                </div>
              </div>

              <div className="row g-12">
                <div className="col-md-4">
                  <div className="my-radio-group mb-2">Sexe</div>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className={
                          errors.sexe && touched.sexe
                            ? "form-check-input is-invalid"
                            : "form-check-input "
                        }
                        type="radio"
                        name="sexe"
                        value="F"
                      />
                      <label className="form-check-label">Féminin</label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className={
                          errors.sexe && touched.sexe
                            ? "form-check-input is-invalid"
                            : "form-check-input "
                        }
                        type="radio"
                        name="sexe"
                        value="M"
                      />
                      <label className="form-check-label">Masculin</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <DesktopDatePicker
                      className="form-control"
                      label="Date naissance"
                      inputFormat="DD/MM/YYYY"
                      value={value}
                      onChange={handleChangeDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={emptyDate}
                          name="date_naissance"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row g-12">
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <Field
                      name="cin"
                      type="text"
                      id="floatingInput"
                      className="form-control"
                      placeholder="Cin"
                    />
                    <label htmlFor="floatingInput">Cin</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <Field
                      name="adresse"
                      type="text"
                      id="floatingInput"
                      className="form-control"
                      placeholder="Adresse"
                    />
                    <label htmlFor="floatingInput">Adresse</label>
                  </div>
                </div>
              </div>

              <div className="row g-12">
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <Field
                      name="tel"
                      type="text"
                      id="floatingInput"
                      className="form-control"
                      placeholder="Téléphone"
                    />
                    <label htmlFor="floatingInput">Téléphone</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="my-radio-group mb-2">Couverture social</div>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="couverture_social"
                        value="CNSS"
                      />
                      <label className="form-check-label">CNSS</label>
                    </div>

                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="couverture_social"
                        value="CNOPS"
                      />
                      <label className="form-check-label">CNOPS</label>
                    </div>

                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="couverture_social"
                        value="Autre"
                      />
                      <label className="form-check-label">Autre</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-12">
                <div className="col-md-4">
                  <div className="my-radio-group mb-2">Type de visite</div>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="type_visite"
                        value="Consultation"
                      />
                      <label className="form-check-label">Consultation</label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="type_visite"
                        value="Controle"
                      />
                      <label className="form-check-label">Controle</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <LoadingButton
                  type="submit"
                  endIcon={<SendIcon />}
                  loading={add.loading}
                  loadingPosition="end"
                  variant="contained"
                  color={
                    add.success === true
                      ? "success"
                      : add.success === false
                      ? "error"
                      : "primary"
                  }
                  disabled={add.success}
                >
                  Ajouter
                </LoadingButton>
              </div>
              {add.success === true && (
                <div className="form-group mt-4">
                  <LoadingButton
                    onClick={handleClickOpen()}
                    endIcon={<SendIcon />}
                    variant="contained"
                  >
                    Ajouter Visite
                  </LoadingButton>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <Dialog fullScreen open={open} onClose={handleClose} scroll={scroll}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Nouvelle Visite
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={scroll === "paper"}>
          <VisiteAdd patient={add.data && add.data.id} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PatientAdd;
