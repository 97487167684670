import axios from "axios";

export const url = "http://localhost:8000/api/";
export const reqInstance = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("userJWT")}`,
  
    'Content-Type': 'application/json; charset=utf-8'

  },
});
