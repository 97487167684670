/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";

const ordoOrphelineSlice = createSlice({
  name: "ordoOrpheline",
  initialState: {
    loading: false,
    data: {},
    dataOne: {},
    addordoOrpheline: { loading: false, data: {} },
  },
  reducers: {
    resetOrdoOrpheline(state) {
      return {
        ...state,
        loading: false,
        data: {},
        dataOne: {},
        addordoOrpheline: { loading: false, data: {} },
      };
    },
    getOrdoOrpheline(state) {
      return { ...state, data: {}, erreur: null, loading: true };
    },
    setOrdoOrpheline(state, action) {
      const data = action.payload;
      return { ...state, data: data, erreur: null, loading: false };
    },
    getOneOrdoOrpheline(state) {
      return { ...state, data: {}, erreur: null, dataOne: {} };
    },
    setOneOrdoOrpheline(state, action) {
      const data = action.payload;
      return { ...state, data: {}, erreur: null, dataOne: data };
    },
    getOrdoOrphelineFailure(state, action) {
      const erreur = action.payload;
      return { ...state, erreur: erreur, loading: false };
    },
    addOrdoOrpheline() {
      return { addordoOrpheline: { loading: true } };
    },
    addOrdoOrphelineSuccess(state, action) {
      const data = action.payload;
      return {
        addordoOrpheline: { loading: false, success: true, data: data },
      };
    },
    addOrdoOrphelineFailure(state, action) {
      const data = action.payload;
      return {
        ...state,
        erreur: data,
        loading: false,
        addordoOrpheline: { loading: false, success: false },
      };
    },
    editOrdoOrpheline(state) {
      return { ...state };
    },
    editOrdoOrphelineSuccess(state) {
      return { ...state, success: true };
    },
    editOrdoOrphelineFailure(state) {
      return {
        ...state,
        success: false,
      };
    },
    deleteOrdoOrpheline(state) {
      return { ...state };
    },
    deleteOrdoOrphelineSuccess(state) {
      return { ...state, success: true };
    },
    deleteOrdoOrphelineFailure(state) {
      return { ...state, success: false };
    },
  },
});

export const {
  getOrdoOrpheline,
  setOrdoOrpheline,
  getOrdoOrphelineFailure,
  addOrdoOrpheline,
  addOrdoOrphelineSuccess,
  addOrdoOrphelineFailure,
  deleteOrdoOrpheline,
  deleteOrdoOrphelineFailure,
  deleteOrdoOrphelineSuccess,
  editOrdoOrpheline,
  editOrdoOrphelineFailure,
  editOrdoOrphelineSuccess,
  getOneOrdoOrpheline,
  setOneOrdoOrpheline,
  resetOrdoOrpheline,
} = ordoOrphelineSlice.actions;

export default ordoOrphelineSlice.reducer;
