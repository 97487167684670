/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from "@reduxjs/toolkit";

const OrdonnanceSlice = createSlice({
  name: "ordonnance",
  initialState: {
    loading: false,
    data: {},
    dataOne: {},
    addordonnance: { loading: false, data: {} },
  },
  reducers: {
    resetOrdonnance(state) {
      return {
        ...state,
        loading: false,
        data: {},
        dataOne: {},
        addordonnance: { loading: false, data: {} },
      };
    },
    getOrdonnance(state) {
      return { ...state, data: {}, erreur: null, loading: true };
    },
    setOrdonnance(state, action) {
      const data = action.payload;
      return { ...state, data: data, erreur: null, loading: false };
    },
    getOneOrdonnance(state) {
      return { ...state, data: {}, erreur: null, dataOne: {} };
    },
    setOneOrdonnance(state, action) {
      const data = action.payload;
      return { ...state, data: {}, erreur: null, dataOne: data };
    },
    getOrdonnanceFailure(state, action) {
      const erreur = action.payload;
      return { ...state, erreur: erreur, loading: false };
    },
    addOrdonnance() {
      return { addordonnance: { loading: true } };
    },
    addOrdonnanceSuccess(state, action) {
      const data = action.payload;
      return { addordonnance: { loading: false, success: true, data: data } };
    },
    addOrdonnanceFailure(state, action) {
      const data = action.payload;
      return {
        ...state,
        erreur: data,
        loading: false,
        addordonnance: { loading: false, success: false },
      };
    },
    editOrdonnance(state) {
      return { ...state };
    },
    editOrdonnanceSuccess(state) {
      return { ...state, success: true };
    },
    editOrdonnanceFailure(state) {
      return {
        ...state,
        success: false,
      };
    },
    deleteOrdonnance(state) {
      return { ...state };
    },
    deleteOrdonnanceSuccess(state) {
      return { ...state, success: true };
    },
    deleteOrdonnanceFailure(state) {
      return { ...state, success: false };
    },
  },
});
export const {
  getOrdonnance,
  setOrdonnance,
  getOrdonnanceFailure,
  addOrdonnance,
  addOrdonnanceSuccess,
  addOrdonnanceFailure,
  deleteOrdonnance,
  deleteOrdonnanceFailure,
  deleteOrdonnanceSuccess,
  editOrdonnance,
  editOrdonnanceFailure,
  editOrdonnanceSuccess,
  getOneOrdonnance,
  setOneOrdonnance,
  resetOrdonnance,
} = OrdonnanceSlice.actions;

export default OrdonnanceSlice.reducer;
