import React, { useState, useEffect } from "react";
import { lastPart } from "../../common/Fonction.js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import parse from "html-react-parser";
dayjs.extend(relativeTime);
import { clearMessage } from "../../ducks/messageSlice";
import { getOneOrdoOrpheline } from "../../ducks/ordoOrphelineSlice";
import { useDispatch, useSelector } from "react-redux";

const OrdoOrpheline = () => {
  const { dataOne } = useSelector((state) => state.ordoOrpheline);
  const { message } = useSelector((state) => state.message);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const id = lastPart(location.pathname);

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getOneOrdoOrpheline(id));
  }, [dispatch, id]);

  useEffect(() => {
    setData(dataOne);
  }, [dataOne, data]);

  return (
    <div className="col-md-12 signup-form">
      {message && (
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      )}
      {data && (
        <div>
          <h1 className="text-center mb-4">
            Ordonnance Orpheline n° {data.id}
          </h1>
          <div className="container overflow-hidden text-center">
            <div className="row row-cols-2 mb-4">
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>Date Ordonnance :</strong>
                  </p>
                  <p>{dayjs(data.ajouter).format("DD-MM-YYYY")}</p>
                </div>
              </div>
              <div className="col">
                <div className="p-2 border bg-light">
                  <p className="mt-2">
                    <strong>type :</strong>
                  </p>
                  <p>
                    <span className="text-capitalize">{data.type}</span>
                  </p>
                </div>
              </div>
            </div>
            {data.type == "medicament" &&
              data.medicaments.map((item, i) => (
                <div
                  key={i}
                  className="row row-cols-3 border row row-cols-sm-3 mb-3"
                >
                  <div className="col">
                    <div className="bg-light p-2">
                      <strong>Médicament</strong>
                      <p className="mb-0">{item.nom}</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="bg-light p-2">
                      <strong>Posologie</strong>
                      <p className="mb-0">{item.posologie}</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="bg-light p-2">
                      <strong>Durée</strong>
                      <p className="mb-0">{item.duree}</p>
                    </div>
                  </div>
                </div>
              ))}

            {data.type == "texte" && (
              <div className=" row mb-3 justify-content-around">
                <div className="col-8 border">
                  <div className="bg-light pt-3">{parse(data.texte)}</div>
                </div>
              </div>
            )}

            {data.type == "certificat" && (
              <div className=" row mb-3 justify-content-around">
                <div className="col-8 border">
                  <div className="bg-light pt-3">{parse(data.certificat)}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdoOrpheline;
