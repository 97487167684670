import { url, reqInstance } from "../../common/authHeader";

export function requestGetConsultation() {
  return reqInstance.get(`${url}consultations?order[id]`);
}

export function requestGetOneConsultation(id) {
  return reqInstance.get(`${url}consultations/${id}`);
}

export function requestAddConsultation(data) {
  return reqInstance.post(`${url}add-consultation`, data);
}

export function requestEditConsultation(id, data) {
  return reqInstance.put(`${url}consultations/${id}`, data);
}

export function requestDeleteConsultation(id) {
  return reqInstance.delete(`${url}consultations/${id}`);
}
